import React, { useState } from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"

import SEO from "../components/seo"
import { ChevronRight } from "react-feather"
import OpenAI from "../images/OpenAI.svg"
import Front from "../images/front-vertical.svg"
import Intercom from "../images/intercom-vertical.svg"
import Zendesk from "../images/zendesk-vertical.svg"
import Shopify from "../images/shopify-vertical.svg"

import logo from "../images/logo.svg"
import ChatIcon from "../components/Icons/chat-icon"
import HelpIcon from "../components/Icons/help-icon"
import Input from "../components/Input/Input"

import SignupModal from "../Features/SignupModal"

const IndexPage = () => {
  const [isEmailFocused, setIsEmailFocused] = useState(false)
  const [isEmailFocused1, setIsEmailFocused1] = useState(false)

  const [isModalOpen, setIsModalOpen] = useState(false)

  const [email, setEmail] = useState("")
  const [emailError, setEmailError] = useState("")
  const [email1, setEmail1] = useState("")
  const [emailError1, setEmailError1] = useState("")
  return (
    <Layout>
      <SEO
        title="Accelerate your customer support team"
        description="Use our AI integrations to instantly generate responses for your customer support team"
      />
      <SignupModal
        open={isModalOpen}
        onClose={() => {
          setIsModalOpen(false)
        }}
        modalUpdatedSucess={() => {
          setIsModalOpen(false)
        }}
        email={email1.length > 0 ? email1 : email}
      />
      <div className="home-container">
        <div className="hero first-hero">
          <div className="hero-left">
            <h1 className="big-h">
              Customer support reinvented for the 21st century
            </h1>
            <p className="big-p">
              Learn from interactions to minimize response time, generate
              responses, and reduce costs — while increasing satisfaction
            </p>

            <div className="access">
              <div style={{ width: "52%" }}>
                <Input
                  className="access-field"
                  type="email"
                  placeholder="enter your email"
                  autoFocus={true}
                  value={email}
                  onChange={e => {
                    setEmail(e.target.value)
                  }}
                  haserror={emailError.length > 0 ? true : false}
                  style={{
                    minWidth: "100%",
                    display: "block",
                  }}
                />
                {emailError && (
                  <span className="email-error">{emailError}</span>
                )}
              </div>
              <button
                className={`access-button darkens dark-button ${
                  isEmailFocused ? "active" : ""
                }`}
                onClick={() => {
                  setEmailError("")
                  if (email.length === 0) {
                    setEmailError("Email is required")
                  } else if (
                    !/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(email)
                  ) {
                    setEmailError("Email is invalid")
                  } else {
                    // Assume all good and show the modal
                    setIsModalOpen(true)
                  }
                }}
              >
                Request access <ChevronRight className="access-chevron" />
              </button>
            </div>
          </div>

          <div className="hero-right">
            <div className="first-col">
              <div className="hero-box">
                <div className="type-information">
                  <div className="type-icon">
                    <div className="wrap">
                      <button className="animated-button"></button>
                    </div>
                  </div>

                  <h3 className="hero-title">AI responses</h3>
                  <ul className="hero-list">
                    <li>Learns your product</li>
                    <li>Learns case history</li>
                    <li>Saves time and effort</li>
                    <li>Reduces support costs</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="second-col">
              <div className="hero-box">
                <div className="type-information">
                  <div className="type-icon">
                    <ChatIcon />
                  </div>

                  <h3 className="hero-title">Chat embed</h3>
                  <ul className="hero-list">
                    <li>Minimalist aesthetic</li>
                    <li>Lightweight (&lt;200kB)</li>
                    <li>Dark mode responsive</li>
                    <li>Fully customizable</li>
                  </ul>
                </div>
              </div>
              <div className="hero-box">
                <div className="type-information">
                  <div className="type-icon">
                    <HelpIcon />
                  </div>

                  <h3 className="hero-title">Help center</h3>
                  <ul className="hero-list">
                    <li>Always up to date</li>
                    <li>Article generation</li>
                    <li>First line of support</li>
                    <li>Follows best practices</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="clipper">
          <div className="hero">
            <div className="hero-center">
              <div className="bg-pulse">
                <div className="wrap">
                  <button className="animated-button"></button>
                </div>
              </div>
              <h2 className="big-h-2">AI response integrations</h2>
              <p className="big-p-2">
                Learns your product and case history to generate instant
                responses for incoming help requests — supercharging agent
                productivity
              </p>

              <div className="integrations">
                <div className="coming-integration">
                  <img src={Zendesk} className="zendesk-logo" />
                  <h3 className="integration-title">Zendesk integration</h3>
                  <p className="integration-description">Coming soon</p>
                </div>
                <div className="available-integration">
                  <img src={Front} className="integration-logo" />
                  <div className="integration-info">
                    <h3 className="integration-title">Front integration</h3>
                    <p className="integration-description">
                      Connect Aide to Front and respond to customer requests in
                      a fraction of the time
                    </p>
                  </div>
                  <Link to="/integrations/front" className="access-button">
                    Learn more <ChevronRight className="access-chevron" />
                  </Link>
                </div>
                <div className="coming-integration">
                  <img src={Intercom} className="intercom-logo" />
                  <h3 className="integration-title">Intercom integration</h3>
                  <p className="integration-description">Coming soon</p>
                </div>
              </div>
              <div className="all-integrations">
                <a href="/integrations">
                  See all integrations{" "}
                  <ChevronRight className="access-chevron" />
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="hero">
          <div className="hero-center">
            <div className="bg-pulse">
              <div className="wrap">
                <button className="animated-button"></button>
              </div>
            </div>
            <h2 className="big-h-2">One-click actions</h2>
            <p className="big-p-2 unwide">
              Actions fulfill customer needs without navigating away to your
              sales platform — on the spot{" "}
            </p>

            <div className="integrations">
              <div className="available-integration">
                <img src={Shopify} className="integration-logo" />
                <div className="integration-info">
                  <h3 className="integration-title">Shopify actions</h3>
                  <p className="integration-description">
                    Instantly process refunds, return labels, or modify customer
                    orders
                  </p>
                </div>
                <button
                  className="access-button darkens"
                  onClick={() => {
                    setIsModalOpen(true)
                  }}
                >
                  Request access <ChevronRight className="access-chevron" />
                </button>
              </div>
              {/* <div className="available-integration">
                <img src={Shopify} className="integration-logo" />
                <div className="integration-info">
                  <h3 className="integration-title">Calendar integration</h3>
                  <p className="integration-description">
                    Instantly process refunds, return labels, or modify customer
                    orders
                  </p>
                </div>
                <button className="access-button darkens">
                  Request access <ChevronRight className="access-chevron" />
                </button>
              </div> */}
            </div>
          </div>
        </div>

        <div className="clipper">
          <div className="social-proof">
            <h2 className="social-heading">Our customers say...</h2>
            <div className="testimonials">
              <div className="truster">
                <div className="box">
                  <div className="box-information">
                    <h3 className="truster-title">Alpha customer</h3>
                    <p className="box-description">
                      We recently started using Aide and it's the first time our
                      support request backlog has been obliterated. We simply
                      cannot do without Aide.
                    </p>
                  </div>
                </div>
              </div>
              <div className="truster">
                <div className="box">
                  <div className="box-information">
                    <h3 className="truster-title">Alpha customer</h3>
                    <p className="box-description">
                      The customizability of the help widget and chatbox are
                      second to none. Let me tell you, I've tried all embedded
                      chat widgets out there this is by far the easiest to
                      interact with.
                    </p>
                  </div>
                </div>
              </div>
              <div className="truster">
                <div className="box">
                  <div className="box-information">
                    <h3 className="truster-title">Alpha customer</h3>
                    <p className="box-description">
                      Our customer support agents are getting so much more done
                      that we no longer needed 5 agents (down to 3 now). It's
                      saving us upwards of $10k a month — not to mention
                      significantly faster responses to customers!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="hero">
          <div className="hero-center hero-last">
            <img src={logo} alt="Aide Logo" style={{ width: "72px" }} />
            <h2 className="big-h-2">Join the 21st century</h2>
            <p className="big-p">
              Join us as we build support from the ground up and get early
              access to our products before general availability
            </p>

            <div className="access last-access">
              <div style={{ width: "52%" }}>
                <Input
                  className="access-field"
                  type="email"
                  placeholder="enter your email"
                  autoFocus={false}
                  value={email1}
                  onChange={e => {
                    setEmail1(e.target.value)
                  }}
                  haserror={emailError1.length > 0 ? true : false}
                  style={{
                    minWidth: "100%",
                    display: "block",
                  }}
                />
                {emailError1 && (
                  <span className="email-error"> {emailError1}</span>
                )}
              </div>
              <button
                className={`access-button darkens dark-button ${
                  isEmailFocused1 ? "active" : ""
                }`}
                onClick={() => {
                  setEmailError1("")
                  if (email1.length === 0) {
                    setEmailError1("Email is required")
                  } else if (
                    !/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(email1)
                  ) {
                    setEmailError1("Email is invalid")
                  } else {
                    // Assume all good and show the modal
                    setIsModalOpen(true)
                  }
                }}
              >
                Request access <ChevronRight className="access-chevron" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
export default IndexPage
